@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
    /* .bg-orange {
        background-color: #fc6f25;
    }

    .text-orange {
        color: #fc6f25;
    } */

    .text-xxs {
        font-size: 0.625rem /* 10px */;
        line-height: 0.875rem /* 14px */;
    }

    .scrolling-wrapper {
        -webkit-overflow-scrolling: touch;
    }

    .scrolling-wrapper::-webkit-scrollbar {
        display: none;
    }

    .scroll-snap-x {
        scroll-snap-type: x proximity;
    }

    .highlight-none {
        -webkit-tap-highlight-color: transparent;
    }

    .bible-content strong,
    .bible-content i {
        @apply pb-2 text-xs italic text-orange;
    }

    .bible-content i {
        @apply pb-2 pr-1 not-italic font-semibold text-gray-700 text-xxs;
        vertical-align: super;
    }

    .bible-content h3 {
        @apply font-normal leading-5 text-white;
    }

    .search-input {
        @apply text-sm py-3 font-semibold sm:py-3.5 placeholder-gray-300 bg-gray-900 rounded-full ring-2 ring-gray-900 focus:placeholder-gray-500 focus:outline-none focus:ring-gray-700 focus:border-gray-700 focus:text-gray-900 focus:bg-gray-100;
    }

    .play-btn {
        @apply text-gray-800 transition duration-500 bg-transparent rounded-full shadow-none focus:outline-none active:bg-orange highlight-none;
    }

    .signin-input {
        @apply text-sm py-3 sm:py-3.5 font-semibold text-center ring-2 ring-gray-300 text-gray-700 placeholder-gray-500 bg-gray-300 rounded-full focus:outline-none focus:ring-gray-800 focus:border-gray-800;
    }

    .signin-btn {
        @apply block w-full font-semibold text-white bg-gray-700 rounded-full ring-2 ring-gray-700 focus:outline-none active:bg-gray-900 disabled:opacity-60 disabled:cursor-not-allowed focus:ring-gray-700;
    }

    input[type="range"] {
        height: 20px;
        height: 3px;
        -webkit-appearance: none;
        margin: 10px 0;
        width: 100%;
    }
    input[type="range"]:focus {
        outline: none;
    }
    input[type="range"]::-webkit-slider-runnable-track {
        width: 100%;
        height: 3px;
        cursor: pointer;
        animate: 0.2s;
        box-shadow: 0px 0px 0px #000000;
        background: #2497e3;
        border-radius: 5px;
        border: 0px solid #000000;
    }
    input[type="range"]::-webkit-slider-thumb {
        box-shadow: 0px 0px 0px #000000;
        border: 1px solid #2497e3;
        height: 13px;
        width: 13px;
        border-radius: 25px;
        background: #a1d0ff;
        cursor: pointer;
        -webkit-appearance: none;
        margin-top: -50.5px;
    }
    input[type="range"]:focus::-webkit-slider-runnable-track {
        background: #2497e3;
    }
    input[type="range"]::-moz-range-track {
        width: 100%;
        height: 3px;
        cursor: pointer;
        animate: 0.2s;
        box-shadow: 0px 0px 0px #000000;
        background: #2497e3;
        border-radius: 5px;
        border: 0px solid #000000;
    }
    input[type="range"]::-moz-range-thumb {
        box-shadow: 0px 0px 0px #000000;
        border: 1px solid #2497e3;
        height: 13px;
        width: 13px;
        border-radius: 25px;
        background: #a1d0ff;
        cursor: pointer;
    }
    input[type="range"]::-ms-track {
        width: 100%;
        height: 3px;
        cursor: pointer;
        animate: 0.2s;
        background: transparent;
        border-color: transparent;
        color: transparent;
    }
    input[type="range"]::-ms-fill-lower {
        background: #2497e3;
        border: 0px solid #000000;
        border-radius: 10px;
        box-shadow: 0px 0px 0px #000000;
    }
    input[type="range"]::-ms-fill-upper {
        background: #2497e3;
        border: 0px solid #000000;
        border-radius: 10px;
        box-shadow: 0px 0px 0px #000000;
    }
    input[type="range"]::-ms-thumb {
        margin-top: 1px;
        box-shadow: 0px 0px 0px #000000;
        border: 1px solid #2497e3;
        height: 13px;
        width: 13px;
        border-radius: 25px;
        background: #a1d0ff;
        cursor: pointer;
    }
    input[type="range"]:focus::-ms-fill-lower {
        background: #2497e3;
    }
    input[type="range"]:focus::-ms-fill-upper {
        background: #2497e3;
    }

    /* TTRANSPARENT */
    input[type="range"] {
        height: 20px;
        height: 3px;
        -webkit-appearance: none;
        margin: 10px 0;
        width: 100%;
    }
    input[type="range"]:focus {
        outline: none;
    }
    input[type="range"]::-webkit-slider-runnable-track {
        width: 100%;
        height: 1px;
        cursor: pointer;
        animate: 0.2s;
        /* box-shadow: 0px 0px 0px #443dff; */
        background: rgba(255, 255, 255, 0);
        border-radius: 5px;
        border: 2px solid rgba(255, 255, 255, 0);
    }
    input[type="range"]::-webkit-slider-thumb {
        /* box-shadow: 0px 0px 0px #000000; */
        border: 1px solid #fc6f25;
        height: 13px;
        width: 13px;
        border-radius: 25px;
        background: #fc6f25;
        cursor: pointer;
        -webkit-appearance: none;
        margin-top: -6.5px;
    }
    input[type="range"]:focus::-webkit-slider-runnable-track {
        background: rgba(255, 255, 255, 0);
    }
    input[type="range"]::-moz-range-track {
        width: 100%;
        height: 1px;
        cursor: pointer;
        animate: 0.2s;
        box-shadow: 0px 0px 0px #443dff;
        background: rgba(255, 255, 255, 0);
        border-radius: 5px;
        border: 2px solid rgba(255, 255, 255, 0);
    }
    input[type="range"]::-moz-range-thumb {
        /* box-shadow: 0px 0px 0px #000000; */
        border: 1px solid #fc6f25;
        height: 13px;
        width: 13px;
        border-radius: 25px;
        background: #fc6f25;
        cursor: pointer;
    }
    input[type="range"]::-ms-track {
        width: 100%;
        height: 1px;
        cursor: pointer;
        animate: 0.2s;
        background: transparent;
        border-color: transparent;
        color: transparent;
    }
    input[type="range"]::-ms-fill-lower {
        background: rgba(255, 255, 255, 0);
        border: 2px solid rgba(255, 255, 255, 0);
        border-radius: 10px;
        box-shadow: 0px 0px 0px #443dff;
    }
    input[type="range"]::-ms-fill-upper {
        background: rgba(255, 255, 255, 0);
        border: 2px solid rgba(255, 255, 255, 0);
        border-radius: 10px;
        box-shadow: 0px 0px 0px #443dff;
    }
    input[type="range"]::-ms-thumb {
        margin-top: 1px;
        box-shadow: 0px 0px 0px #000000;
        border: 1px solid #fc6f25;
        height: 13px;
        width: 13px;
        border-radius: 25px;
        background: #fc6f25;
        cursor: pointer;
    }
    input[type="range"]:focus::-ms-fill-lower {
        background: rgba(255, 255, 255, 0);
    }
    input[type="range"]:focus::-ms-fill-upper {
        background: rgba(255, 255, 255, 0);
    }
}

html,
body,
#__next {
    width: 100%;
    min-height: 100%;
    @apply bg-gray-50;
}

#__next {
    display: flex;
    flex-direction: column;
    overflow-y: hidden;
    align-items: stretch;
}
